import { PageProps } from "gatsby";
import "github-markdown-css";
import React from "react";
import { ContentWrapper } from ".";
import Layout from "../../components/layout";
import { Footer, Navigation } from "../index.zh-CN";

export default function Index({ location }: PageProps) {
    return (
        <Layout title="隐私和条款 - Netless">
            <Navigation invert />
            <ContentWrapper className="markdown-body">
                <h1 id="privacy">隐私条款</h1>
                <h2>一、隐私条款的覆盖范围</h2>
                <p>
                    您的隐私安全对于我们而言非常重要，我们也非常乐意告知您我们是如何收集、使用您的相关信息。
                    本文旨在帮助您了解如下内容：
                </p>
                <p>
                    <li>我们收集的信息</li>
                    <li>我们将如何使用收集到的信息；</li>
                    <li>在我们产品内部您的信息是如何被“使用”的；</li>
                    <li>我们如何存储所收集到的信息并如何保障其安全；</li>
                    <li>我们收集到的信息是如何跨境传输的；</li>
                    <li>其他有关隐私的信息；</li>
                </p>
                <p>
                    本隐私协议在您使用 Netless 产品、Netless 系列产品（如 Netless White
                    SDK）和服务或者其他方式调用使用 Netless
                    时全程有效（除其他单独的隐私协议注释外）。此政策还解释了您有权决定是否允许我们收集您的行为数据。如果您不同意此政策，请勿访问或使用我们的服务或与我们业务的任何其他方面进行互动。当我们在本政策中提及“Netless
                    ”，“我们”或“我们”时，我们指的是 Shanghai Woruo Information Technology Co.,
                    Ltd.,，它控制 Netless 在您使用服务时收集的信息。Netless
                    提供了协作工具，包括我们的网站，桌面和移动产品，这些产品可以帮助您保持组织并与他人沟通。我们还拥有并运营多个网站并提供相关服务。我们在本政策中将所有这些产品以及我们的其他服务和网站称为“服务”。
                </p>
                <h2>二、我们收集的信息</h2>
                <p>
                    我们将收集您提供给我们的信息，包括您在使用我们的服务时产生的信息以及其他信息来源向我们提供时的信息，如下所述
                </p>
                <h2>您提供给我们的信息</h2>
                <p>
                    <p>我们将收集您在我们网站或服务中直接输入提交到额数据。</p>
                    <p>
                        1.
                        账户和个人资料信息：我们将在您注册帐户、创建或修改您的个人资料，设置偏好，注册或通过服务进行购买时收集您的有关信息。例如，您在注册服务时提供您的联系信息以及某些情况下的帐单信息将会被收集。您还可以选择将个人资料照片，个人简历和其他详细信息添加到您的个人资料信息中以显示在我们的服务中。在您修改或者选择个人偏好时，我们也将持续获取与追踪您的偏好信息。
                    </p>
                    <p>
                        2. 您通过我们的产品所提供的内容：我们将通过您使用的 Netless
                        网页，桌面和移动产品，收集和存储您发布，发送，接收和分享的内容。此内容包括您的任何信息或者您选择包含的信息。内容还包括您上传到服务的文件和链接。我们收集和存储的内容包括：您附加到任务上的文件，任务列表，项目，列表或企业的名称或任务的描述，您在任务、分享、文件中的评论，上传的图片、表情以及文件等。
                    </p>
                    <p>
                        3.
                        您通过我们网站所提供的信息：我们还通过我们所拥有、运营的网站、社交媒体收集了您所提交的信息。例如，当您提供反馈或参与任何互动功能调查，促销活动，抽奖活动或活动时，您向我们提供内容都会被我们收集。
                    </p>
                    <p>
                        4.
                        您通过我们的支持渠道提供的信息：我们还将收集您通过我们的客户支持服务或者其他支持服务所提交的问题与信息。当您打开我们的客户支持聊天窗口或是邮件时候，系统会要求您提供联系信息，您所遇到的问题、截图以及任何其他有助于解决问题的文档、截屏等信息。
                    </p>
                    <p>
                        5.
                        支付信息：当您注册某些付费服务时，我们会收集某些付款和账单信息。例如，在您为我们的产品付费时，我们要求您指定姓名和联系信息等。您也可能会提供付款账号信息，如您的银行卡卡号等。
                    </p>
                </p>
                <h2>三、您使用服务时自动收集的信息</h2>
                <p>
                    当您浏览和使用我们的服务时（如浏览网站，或者在网页中做相应的操作），我们将自动收集您的一些相关信息。
                </p>
                <p>
                    1.
                    您在我们产品中的使用行为：当您访问我们的任何服务并与之互动时，我们会跟踪您的某些信息。这些信息包括您正在使用的功能，如：点击任务，选择团队或者点击其他链接；同时包括了您上传到服务中的附件对的类型，大小与文件名；同时也包括了您所经常使用的搜索词，您在产品中是如何与他人互动的等等。我们还收集了您合作的团队和人员的信息以及您与他们互动的方式，例如与您最频繁合作和沟通的人员。
                </p>
                <p>
                    2.
                    设备与连接信息：在您使用我们服务时，我们收集了有关您的计算机，手机，平板电脑或您用于访问服务的其他设备的信息。此设备信息包括您在安装，访问，更新或使用我们的服务时的连接类型和设置。我们还通过您的设备收集关于您的操作系统，浏览器类型，IP
                    地址，分享/退出页面的URL，设备标识符以及应用奔溃相关数据的信息。我们使用您的IP地址、国家或地区偏好来估算您的位置，以便为您提供更好的服务体验。我们所收集的信息有多少取决于您用于访问服务的设备的类型和设置。
                </p>
                <p>
                    3. Cookies 和其他跟踪技术：Netless
                    和我们的第三方合作伙伴（如我们的分析合作伙伴）使用Cookie
                    和其他跟踪技术（如网页打点等）来识别您的身份。Cookie是仅限文本的信息字符串，网站会将这些字符串传输到计算机硬盘上浏览器的
                    Cookie 文件中， 以便网站能够记住您的身份信息以及其他详细信息。Cookie
                    可能由网站设置或来源自第三方，比如广告商。Cookie
                    本身不用于或意图用于从用户的计算机读取任何信息 （Cookie 本身的内容除外）。Cookie
                    只是网站所使用的最初将其放置在您硬盘上的标识符。同一台服务器可以检索到 Cookie
                    信息的实际内容，
                    从而标识计算机并进而根据主服务器上存储的信息自定义、跟踪或控制站点的使用情况。我们会通过这些追踪手段来帮助您更好的使用
                    Netless，也帮助我们更好的改善对应的功能。您可以通过修改浏览器设置来选择接受或者拒绝Cookie，但是如果您禁用了Cookie，您将不能使用
                    Netless 的各项交互功能。
                </p>
                <h2>我们从其他来源渠道收集到的信息</h2>
                <p>
                    我们从服务中的其他用户、其他第三方服务和相关公司以及我们的业务和渠道合作伙伴那里收到有关您的信息。
                </p>
                <p>
                    1.
                    服务中的其他用户：我们服务的其他用户可能会在服务提交内容时提供有关您的信息。例如：在任务备注中写您的名字，或者在聊天中提及您的信息。我们也会收到来自其他服务用户的电子邮件地址，以便邀请您加入服务。同样，您的企业管理员也可能会指定您为项目管理员或者团队管理员（登录您的邮件或者电话），以便您的团队成员在需要时候可以联系您。
                </p>
                <p>
                    2.
                    您关联到您的帐户的其他服务：当您启用github、微信等第三方应用或将第三方服务与我们的服务集成或关联时，我们会收到有关您的信息。例如，如果您使用微信凭据创建帐户或登录服务，我们会根据您的微信个人资料设置的许可收到您的姓名等信息，以便对您进行身份验证。您或您的管理员可能还会将我们的服务与您使用的其他服务相集成，例如允许您通过我们的服务访问，存储，共享和编辑来自第三方的某些内容。例如，您可以授权我们的服务在服务界面中访问和显示来自第三方文档共享服务的文件。又或者，您可以授权我们的服务同步联系人列表或地址簿，以便您可以轻松地与服务中的联系人进行联系，或邀请他们与我们在服务上进行协作。我们在将我们的服务与第三方服务链接或整合时收到的信息取决于该第三方服务所控制的设置，权限和隐私政策。因此您应该始终关注并了解这些第三方服务中的隐私设置和通知，以了解可能向我们透露或与我们的服务共享的数据。
                </p>
                <p>
                    3. Netless
                    的合作伙伴：我们与各大合作伙伴进行合作，围绕我们的产品与合作伙伴的产品进行单点登录、数据打通、培训和其他服务。其中一些合作伙伴还帮助我们推广产品，为我们带来销售线索并转售我们的产品。我们会收到来自这些合作伙伴的信息，如账单信息、技术联系信息、公司名称、您所感兴趣的产品等。
                </p>
                <h2>三、我们将如何使用收集到的信息</h2>
                <p>
                    我们如何使用我们收集的信息部分取决于您所使用的服务，如何使用它们以及您所设置的偏好，以下是我们使用我们收集的关于您的信息的具体目的
                </p>
                <p>
                    1.
                    为您提供更好的服务与个性化体验：我们使用关于您的信息向您提供服务，包括识别您在什么地方登陆，提供什么样的用户支持并运营与维护整体服务。例如，我们使用您在帐户中提供的名称和图片来向其他服务用户标识您（如头像）。我们的服务还包括个性化您的体验与定制功能，提高您使用
                    Netless
                    的效率，并通过自动化的分析与计算，提高他人与您沟通协作的能力与效率，更好的帮助您和您的团队构建稳固与准确的关联关系。我们可能会使用您的电子邮件域来推断您与特定组织或行业的联系，以个性化您在我们网站上收到的内容和体验。
                </p>
                <p>
                    2.
                    用于研究与开发迭代：我们一直在寻找方法使我们的服务更智能，更快速，更安全，更集成，更有效的方式来帮助您获得更好的使用体验。我们通过集体学习了解人们如何使用我们的服务并不断收集用户直接向我们提供的反馈，以帮助我们排查问题并确定产品趋势，用法，活动模式以及服务整合和改进的方式。例如我们在每次迭代与更新时，会记录新功能的使用量，用户的使用链路来设计更好的更人性化的功能。在某些情况中，我们会将这部分的调研数据与研究数据提供给对应的研究机构用以做更深层的产品研究与学术研究。
                </p>
                <p>
                    3.
                    用于做更好的和您沟通交流：我们使用您的联系信息通过电子邮件和服务内容发送交易邮件，包括确认您的购买，提醒您订阅到期，回应您的意见问题与需求，提供客户支持并向您发送更新、通知、告警等。根据您的设置，当您或其他人在服务中进行互动时，我们会向您发送电子邮件通知，例如，当您在任务中被人提及或在将您添加到任务时。
                </p>
                <p>
                    4.
                    用于技术服务：我们使用您的信息来解决您遇到的技术问题，响应您的协助请求，分析崩溃信息并修复和改进服务。如果您允许我们这样做，我们会与
                    Netless 技术专家分享您的信息，以回应与支持相关的请求。
                </p>
                <p>
                    5.
                    用于加强安全保护与鉴权：我们使用有关您和您的服务使用情况的信息来验证帐户和活动，监控可疑或欺诈活动并确定是否违反服务政策。
                </p>
                <p>
                    6.
                    用于保护我们合法的商业利益和合法权益：在法律要求或我们认为有必要保护我们的合法权利，利益和他人利益的情况下，我们使用有关您的信息来处理法律诉讼，合规，监管和审计职能，以及与收购，合并或出售业务相关的披露。
                </p>
                <h2>四、我们如何共享我们收集到的信息</h2>
                <p>
                    我们开发并提供协作工具，我们希望它们能更好的帮助您进行工作。这也就意味着我们将通过服务或者第三方的形式共享收集到的信息。我们以下述方式分享我们收集的有关您的信息，包可能的业务转移相关的信息，但我们承诺绝不向广告客户或其他第三方出售您的信息。
                </p>
                <h2>与服务中的其他用户共享</h2>
                <p>
                    1.
                    用于协作、合作：您可以创建包含您信息的任务或其他内容，并添加授予其他人访问权限，又或者授予他人共享、编辑、复制和下载的权限。当您与特定内容共享或互动时，服务的某些协作功能会向其他服务用户显示部分或全部个人资料信息。例如，当您评论任务时，我们会在您的评论旁边显示您的个人头像和名称，以便其他有权访问此任务的用户了解是谁发表了评论。同样，当您加入团队或组织时，您的姓名，个人资料图片和联系信息将显示在其他团队成员的列表中，以便他们可以查找并与您互动。请注意，有些任务或者项目可以设置为公开，这意味着发布在该项目上的任何内容（包括关于您的信息）都可以公开查看。您可以随时查看您的项目设置，以确认某个特定的项目是否是公开的。
                </p>
                <p>
                    2.
                    论坛社区：我们的网站提供可公开访问的博客，论坛，错误跟踪器。您在这些网站上提供的任何信息，包括用于发布信息的账号x相关的个人信息资料，可能被任何访问网站的公众阅读，收集和使用，即使您终止了您的账户，您的帖子和某些配置文件信息也可能会被保留。我们建议您在输入这些信息时考虑其安全与隐私性。
                </p>
                <h2>与第三方合作伙伴共享</h2>
                <p>
                    我们与第三方分享信息，帮助我们运营，提供，改进，整合，定制，支持和销售我们的服务。
                </p>
                <p>
                    1.
                    第三方应用：您或者您的管理员或其他服务用户可以选择添加新功能或通过在服务中启用应用中心的方式来进行工作。这样做可能会让第三方应用程序访问您的帐户和关于您的信息，如您的姓名和电子邮件地址，以及您选择与这些应用程序相关的任何内容。
                    第三方应用程序政策和程序不受我们控制，本隐私政策不包括第三方应用程序如何使用您的信息。您应该始终关注并了解这些第三方服务中的隐私设置和通知，以了解有关其隐私和信息处理的更多信息。如果您反对与这些第三方分享您的信息，请停用这些第三方应用。
                </p>
                <p>
                    2.
                    指向第三方网站的链接：服务可能包含指向您的其他网站或服务的链接，这些网站或服务的隐私惯例可能与我们的不同。您需要注意的是您使用以及您提交给任何第三方网站的任何信息均受其隐私政策的约束，而不是本网站的隐私政策。
                </p>
                <h2>五、我们如何存储收集到对的信息并如何保障其安全性</h2>
                <p>
                    1.
                    信息的存储和安全：我们使用阿里云提供的云服务托管我们收集的信息，并使用技术措施来保护您的数据。尽管我们极尽可能的保护您信息的安全，但由于互联网的固有特性，没有任何系统是绝对安全的，我们无法保证数据在通过互联网传输过程中和存储在我们系统时的绝对完全，我们会尽一切可能防止和防护安全入侵事件的发生。但是如果一旦发生该类事件，我们将在合理的时间内第一时间告知。
                </p>
                <h2>六、信息将被存储多久</h2>
                <p>
                    信息将被存储多久是由该信息的种类所决定的，我们在文章下述中将详细描述。当过了存储事件后，我们会删除或匿名您的信息。但当删除操作无法进行的时候（例如，因为信息已存储在备份存档中）我们将安全地存储您的信息，并将其隔离，直至删除成为可能。
                </p>
                <p>
                    1.
                    我们将保留您的帐户信息，直到您删除您的帐户。与此同时，我们还将保留一些必要的信息，以遵守我们的法律义务，解决争议，执行我们的协议，支持业务运营并继续开发和改进我们的服务。如果我们将要保留这些信息用于服务的改进和产品的迭代，我们会将数据脱敏并且我们只会利用这些信息进行集体性的分析而不会针对您的个人数据做专门分析。
                </p>
                <p>
                    2.
                    您在我们服务中所分享的信息:如果您的帐户已停用或被停用，那么您的部分信息和您提供的内容将保留，以便您的团队成员或其他用户充分使用服务。例如，我们会继续显示您提供给项目或任务中的评论和内容。
                </p>
                <p>
                    3.
                    市场偏好信息:如果您选择接收我们的营销电子邮件，我们会保留有关您的营销偏好的信息，除非您明确要求我们删除此类信息。我们将保留从创建日期起的合理时间段内从
                    Cookie 和其他跟踪技术中所获得的信息
                </p>
                <h2>我们收集到的信息是如何跨境传输的</h2>
                <p>
                    Netless
                    提供了国内区域与海外区域的服务，我们仅将如下数据进行国内区域与海外区域的互通，用以帮助提供更好的使用体验。(如用户基本信息，如您的账号，邮箱，个人信息和您的设备信息）
                </p>
                <h2>七、其他重要的隐私策略</h2>
                <p>
                    我们的产品适用于个人使用和组织使用，在通过组织（例如您的雇主）向您提供服务的情况下，该组织是服务的管理员，并且负责其拥有控制权的最终用户和服务站点。如果是这种情况，请将您的数据隐私需求提交给管理员，因为您对服务的使用受制于该组织的政策。我们不对管理员组织的隐私或安全做法负责，这可能与本政策有所出入。
                    即使服务目前没有由组织管理，如果您是由组织管理的团队的成员，或者如果您使用组织提供的电子邮件地址（例如您的工作电子邮件地址）访问服务。那么该团队的管理员或您的组织电子邮件地址相关联的域的所有者（例如您的雇主）可能会对您的帐户进行管理控制并在以后使用我们的服务。如果发生这种情况，你会收到通知。
                    任务拥有者、项目管理员、企业管理员能够限制您对任务、项目、企业的访问与权限。在某些情况下，企业管理员还可以：
                </p>
                <li>要求您重置您的账号密码；</li>
                <li>限制，暂停或终止您访问服务或您的帐户；</li>
                <li>控制您编辑，限制，修改或删除帐户信息的能力；</li>
                <li>更改您的帐户信息，包括个人资料信息或与您帐户相关的电子邮件地址；</li>
                <li>访问您的帐户中的信息；</li>
                <li>开启或关闭第三方应用；</li>
                <p>
                    如果您不希望管理员能够控制您的帐户或使用服务，您可以退出企业、项目、任务，并从您的账户中删除任何包含管理员拥有或控制的域的电子邮件。一旦管理员声明对您的帐户或使用服务的控制权，您可能无法在未经管理员批准的情况下更改与您的帐户相关联的电子邮件地址。
                </p>
                <h2>八、隐私政策的变更</h2>
                <p>
                    我们可能会不时更改此隐私政策，我们会在此页面上发布任何隐私政策更改，如果更改很重要，我们将通过在服务主页上添加通知，登录屏幕或向您发送电子邮件通知来通知。我们还会将此隐私政策的先前版本保留在档案中供您审核。我们鼓励您在使用服务时了解我们的隐私政策，随时了解我们的信息可以帮助您更好保护您的隐私。如果您不同意本隐私政策的任何更改，您需要停止使用服务并停用您的帐户。
                </p>
            </ContentWrapper>
            <ContentWrapper className="markdown-body">
                <h1 id="service">服务条款</h1>
                <h2>一、合约定义</h2>
                <p>
                    1. 本网站（本合约中指包括但不限于 netless.link 、 console.netless.link
                    等网站和相关应用）由上海沃若信息科技有限公司（以下简称“沃若公司”）所有和运作。
                </p>
                <p>
                    2.
                    本合约是网站用户(包括个人、企业及其他组织)与网站运营企业沃若公司之间的法律契约。用户访问、浏览和注册本网站即代表接受本合约条款的约束，用户对本网站的访问和使用应以接受并遵守本合约所载明的条款和条件为前提。
                </p>
                <h2>二、知识产权申明</h2>
                <p>
                    1.
                    本网站的包括但不限于应用程序、源代码、商标、标示图案(LOGO)、界面设计、应用程序编程接口(API)等所关联的所有知识产权均属沃若公司所有。用户不得复制、修改、传播或在非本网站所属的服务器上做镜像或者以其它方式进行非法使用。
                </p>
                <p>
                    2.
                    用户在本网站平台所创建的独创性数据归属该用户所有，该用户有权对前述数据进行任何形式的处置，包括从平台中复制，导出和删除。
                </p>
                <p>
                    3.
                    用户应当确保其公开或存储（方式包括但不限于：上传、保存、公布、发布等）于本网站及相关服务器的内容不存在任何侵犯其他第三方知识产权的情形。若存在本条所述情形，沃若公司有权根据具体情节针对上述内容，采取包括但不限于限制、屏蔽、删除、修改等手段，由此给网站用户造成的损失由网站用户自行承担；若沃若公司因网站用户存在本条所述行为而向其他第三方承担法律责任的，沃若公司有权向网站用户追偿全部损失。
                </p>
                <h2>三、网站使用规则</h2>
                <p>1. 用户在使用本网站服务过程中，必须遵循以下规则：</p>
                <p>
                    <li>遵守中国有关的法律法规；</li>
                    <li>不得对本网站服务进行任何形式的对其他第三方的再授权使用、销售或转让；</li>
                    <li>
                        不得为设计开发竞争产品对本网站进行任何形式的反向工程、反向编译、反汇编，或在竞争产品抄袭模仿本网站的设计；
                    </li>
                    <li>不得滥用本网站的通信功能发送垃圾邮件和短信；</li>
                    <li>
                        不得对本网站的连续服务和商誉构成损害的其他行为，包括对网站服务器的攻击；
                    </li>
                    <li>遵守本协议中的全部约定。</li>
                </p>
                <p>
                    2.
                    如用户在使用本网站服务时违反任何上述规定，沃若公司有权要求用户改正或直接采取一切必要的措施（包括但不限于暂停或终止用户使用本网站服务的权利并追讨因此带来的损失）以减轻用户不当行为造成的影响。
                </p>
                <p>
                    3.
                    用户所拥有的互联网域名所对应的电子邮件地址作为用户权证的唯一识别信息。当用户不再拥有或完全控制在本网站登记的域名时，沃若公司有权随时删除所有的用户数据。在高级模式下，系统支持多个域名电子邮件地址，当最早登记的主域名权属发生改变时，沃若公司有权随时删除主域名及其关联域名所有的用户数据。
                </p>
                <p>
                    4. 用户须对在 White
                    的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐户导致其他用户误认；否则
                    White 有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。
                </p>
                <p>
                    5. 用户直接或通过各类方式（如 RSS 源和站外 API 引用等）间接使用 White
                    服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请停止使用
                    White 所提供的全部服务。
                </p>
                <p>
                    6. 用户承诺不得以任何方式利用 White
                    直接或间接从事违反中国法律、以及社会公德的行为， White
                    有权对违反上述承诺的内容予以删除。
                </p>
                <p>
                    7. 用户不得利用 White 服务制作、上载、复制、发布、传播或者转载如下内容：
                    <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
                    <li>损害国家荣誉和利益的；</li>
                    <li>煽动民族仇恨、民族歧视，破坏民族团结的；</li>
                    <li>破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
                    <li>散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
                    <li>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
                    <li>侮辱或者诽谤他人，侵害他人合法权益的；</li>
                    <li>含有法律、行政法规禁止的其他内容的信息。</li>
                </p>
                <p>
                    8. White 有权对用户使用 White 的情况进行审查和监督，如用户在使用 White
                    时违反任何上述规定， White
                    或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用
                    White 的权利）以减轻用户不当行为造成的影响。
                </p>
                <p>
                    9. White
                    有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及
                    White 社区指导原则对侵权信息进行处理。
                </p>
                <h2>四、用户信息保护</h2>
                <p>
                    1.
                    本网站将尽可能采取一切措施保护用户数据及个人信息资料安全，只根据用户在网站上的行为指示来分发用户的信息，不会向任何第三方公开或共享用户的具体数据。
                </p>
                <p>
                    2.
                    本网站保留使用汇总统计性信息的权利，在不透露单个用户隐私资料的前提下，本网站有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
                </p>
                <h2>五、免责申明</h2>
                <p>
                    1.
                    本网站将尽最大努力保障软件平台的连续可靠运行，但鉴于网络服务的特殊性，本网站对可能发生的网络服务的中断或终止所可能导致的一切损失，不承担任何法律责任及经济赔偿。
                </p>
                <p>
                    2.
                    本网站将尽最大努力保障客户数据的安全备份，但无法承诺100%的数据恢复，对因数据丢失带来的任何直接或间接损失不承担任何责任。
                </p>
                <p>
                    3.
                    在发生需要从备份文件中恢复数据的情形时，本网站通常需要4小时，最长48小时完成，在此时间范围内的数据恢复视作服务是连续的。
                </p>
                <p>
                    4.
                    对于因不可抗力或本网站不能控制的原因造成的网络服务中断和长时间终止，本网站不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
                </p>
                <p>
                    5.
                    本网站将尽可能采取一切措施保护用户数据及个人信息资料安全。但用户通过使用或安装第三方应用打开
                    White
                    中的文件，该文件会在第三方应用中打开，由于第三方应用而造成的损失，本网站不承担任何法律责任及经济赔偿。
                </p>
                <h2>六、合约变更</h2>
                <p>
                    1.
                    本网站有权随时对本合约的条款进行修订，一旦本合约的内容发生变更，本网站将会在修订生效日前一个工作日更新。
                </p>
                <p>2. 如用户继续使用网络服务，则视为用户接受本网站对合约相关条款所做的修订。</p>
                <h2>七、法律适用</h2>
                <p>
                    1.
                    本合约的订立、执行和解释以及争议的解决均应适用中华人民共和国法律并受中国法院管辖。
                </p>
                <p>
                    2.
                    协议双方就本合约内容或其执行发生任何争议，应首先力争友好协商解决；协商不成时，任何一方均可向沃若公司注册地所在的人民法院提起诉讼。
                </p>
            </ContentWrapper>
            <Footer location={location} />
        </Layout>
    );
}
